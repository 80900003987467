import {
  GridCards,
  RichText,
  GridCard,
  toImageProps,
  isRichTextContentEmpty,
  EGridCardImagePaddings,
  EDefaultCardV2ImagePosition,
} from '@front/shared/ds';
import React, { useMemo } from 'react';

import type { Section } from '@shared/master-types';

export type TGridCardsConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'grid-cards' }
>;

type TCard = Required<TGridCardsConnectedProps>['cards'][number];

export const GridCardsConnected: React.FC<TGridCardsConnectedProps> = props => {
  const { cards = [], ...rest } = props;

  const banners: React.ComponentProps<typeof GridCard>[] = useMemo(() => {
    return cards.map(card => {
      const {
        image,
        title,
        description,
        imagePosition,
        imagePaddings,
        topIcon,
      } = card as TCard;

      const defaultCard = {
        ...card,
        image: toImageProps(image),
        title:
          title && !isRichTextContentEmpty(title) ? (
            <RichText content={title} />
          ) : null,
        description:
          description && !isRichTextContentEmpty(description) ? (
            <RichText content={description} />
          ) : null,
        imagePosition: imagePosition as EDefaultCardV2ImagePosition,
        imagePaddings: imagePaddings as EGridCardImagePaddings,
        topIcon: toImageProps(topIcon),
      };

      return defaultCard;
    });
  }, [cards]);

  return <GridCards banners={banners} {...rest} />;
};

export default GridCardsConnected;
